import '@cms/cochlear-sitecore-forms-react/dist/styles/index.scss';
import Form from '@cms/cochlear-sitecore-forms-react';
import React from 'react';
import resolveSite from '../../../SiteResolver';

function SitecoreForm({ fields }) {
  const siteObject =
    typeof window !== 'undefined'
      ? resolveSite(window.location.pathname)
      : { country: '', lang: 'en' };

  const { placeholderData, ...formData } = fields;

  return (
    <Form {...placeholderData} formData={formData} siteObject={siteObject} />
  );
}

export default SitecoreForm;
